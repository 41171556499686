import { Injectable } from '@angular/core';

import { handleError$ } from '@model/error';
import { FirestoreService } from '@services/firestore-service/firestore-service';

//Observable
import { Observable, combineLatest, of, BehaviorSubject, firstValueFrom, isObservable } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

// model
import { Business } from "@model/business/business";
import { PathService } from '@services/path-service/path-service';

// shared
import { isNil } from '@shared/lodash';
import { User } from '@model/users/user';
import { parseProfile } from '@shared/users';
import { Profile } from '@model/users/profile';
import { CallableService } from '@services/callable-service/callable-service';
import { UpdateBusinessConfigPayload, UpdateBusinessPayload } from './types';
import { BusinessType, GroupBusinessPayload, UpsertHoursOperationPayload } from '@model/types/shared';
import { doShareIf } from '@shared/do-share';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {
    /**
     * Es la posición en el indice de Profiles en user.profile
     */
    private currentProfileIndex$ = new BehaviorSubject<number>(0);

    constructor(
        private path: PathService,
        private callableService: CallableService,
        private fsService: FirestoreService) {
    }

    public get currentProfileIndex(): number {
        return this.currentProfileIndex$.value;
    }

    public set currentProfileIndex(value: number) {
        this.currentProfileIndex$.next(value);
    }

    /**
     * Retorna un Map de todos los business asociados al perfil del user
     * @param user$ 
     * @return {Observable<Record<string, Business>>}
     */
    public getAllBusinessMap$(user$: Observable<User>, share: boolean = false): Observable<Record<string, Business>> {
        const allBusinessArr$ = this.getAllBusiness$(user$);

        const result$ = allBusinessArr$.pipe(
            map(allBusinessArr => {
                const businessMap: Record<string, Business> = {};
                allBusinessArr.forEach(business => {
                    businessMap[business.businessId] = business;
                });

                return businessMap;
            })
        );

        return doShareIf(result$, share);
    }

    /**
     * Retorna todos los business para el perfil del usuario
     * @param {Observable<User> | User} user$ 
     * @param {string} groupId Si existe filtra el resultado dejando solamente los business de ese grupo
     * @return {Observable<Business[]>}
     */
    public getAllBusiness$(userOrObs: Observable<User> | User, groupId?: string): Observable<Business[]> {
        const user$ = isObservable(userOrObs) ? userOrObs : of(userOrObs);

        return user$.pipe(
            switchMap(user => {
                if (!isNil(user)) {
                    const allBusinessArr$ = user.profiles
                        .map(profileStr => parseProfile(profileStr))
                        .filter(profile => !isNil(groupId) ? profile.groupId === groupId : true)
                        .map(profile => this.getBusiness$(profile.businessId))

                    return combineLatest(allBusinessArr$).pipe(
                        map(businessArr => businessArr.filter(business => !isNil(business)))
                    )
                }

                return of([]);
            })
        )
    }

    /**
     * Retorna todos los business del grupo, sin hacer filtros
     * @param {string} groupId 
     * @return {Observable<Business[]>}
     */
    public getAllBusinessByGroup$(groupId: string): Observable<Business[]> {
        return this.fsService.colWithIds2$<Business>(this.path.colBusiness(), (queryFn) => {
            return queryFn
            .where("enabled", "==", true)
            .where("groupId", "==", groupId)
        }, { source: 'server'}).pipe(catchError(handleError$()));
    }

    /**
     * Retorna todos los business del grupo, sin hacer filtros
     * @param {string} groupId 
     * @return {Promise<Business[]>}
     */
    public getAllBusinessByGroup(groupId: string): Promise<Business[]> {
        return firstValueFrom(
            this.getAllBusinessByGroup$(groupId),
        )
    }

    /**
     * Retorna todos los business para el perfil del usuario
     * @param {Observable<User> | User} user$ 
     * @param {string} groupId Si existe filtra el resultado dejando solamente los business de ese grupo
     * @return {Promise<Business[]>}
     */
    public getAllBusiness(userOrObs: Observable<User> | User, groupId?: string): Promise<Business[]> {
        return firstValueFrom(
            this.getAllBusiness$(userOrObs, groupId),
        )
    }

    /**
     * Obtiene el business actual
     * @param {Observable<User> | User} userOrObs 
     * @return {Observable<Business>}
     */
    public currentBusiness$(userOrObs: Observable<User> | User): Observable<Business> {
        const user$ = isObservable(userOrObs) ? userOrObs : of(userOrObs);
        return this.currentProfile$(user$).pipe(
            switchMap(profile => profile ? this.getBusiness$(profile.businessId) : of(null))
        );
    }

    public currentBusiness(userOrObs: Observable<User> | User): Promise<Business> {
        return firstValueFrom(
            this.currentBusiness$(userOrObs)
        );
    }

    /**
     * Obtiene el Profile actual del usuario
     * @param {Observable<User>} user$
     * @return {Observable<Profile>}
     */
    public currentProfile$(user$: Observable<User>): Observable<Profile> {
        return combineLatest([user$, this.currentProfileIndex$]).pipe(
            map(([user, currentProfileIndex]) => this.currentProfileFromIndex(user, currentProfileIndex))
        );
    }

    /**
     * Obtiene el Profile actual del usuario
     * @param {Observable<User>} user$
     * @return {Promise<Profile>}
     */
    public currentProfile(userOrObs: Observable<User> | User): Promise<Profile> {
        const user$ = isObservable(userOrObs) ? userOrObs : of(userOrObs);
        return firstValueFrom(
            this.currentProfile$(user$)
        );
    }

    /**
     * Actualiza la configuración del business, 
     * parte de esta configuración va hacia el Business y parte hacia BusinessConfig
     * Esta operación podría estar en Business o en BusinessConfig
     * @param config 
     * @return {boolean} 
     */
    public updateConfig(config: UpdateBusinessConfigPayload): Promise<boolean> {
        return this.callableService.runCallableResult<UpdateBusinessConfigPayload, boolean>("update-business-config", {
            ...config
        }, { delUndefined: true });
    }

    /**
     * Actualiza datos de un conjunto business
     * @param {UpdateBusinessPayload} config 
     * @return {boolean} 
     */
    public updateBusiness(config: UpdateBusinessPayload): Promise<boolean> {
        return this.callableService.runCallableResult<UpdateBusinessPayload, boolean>("update-business", {
            ...config
        }, { delUndefined: true });
    }

    /**
     * 
     * @param {string} groupId 
     * @param {strign} businessId 
     * @return {string[]}
     */
    public getBusinessHoursOperation(groupId: string, businessId: string): Promise<string[] | undefined> {
        return this.callableService.runCallableResult<GroupBusinessPayload, string[]>("get-business-hours-operation", {
            groupId,
            businessId
        });
    }

    /**
     * 
     * @param {string} groupId 
     * @param {strign} businessId 
     * @return {string[]}
     */
    public getDeliveryHoursOperation(groupId: string, businessId: string): Promise<string[] | undefined> {
        return this.callableService.runCallableResult<GroupBusinessPayload, string[]>("get-delivery-hours-operation", {
            groupId,
            businessId
        });
    }

    public upsertBusinessHoursOperation(groupId: string, businessId: BusinessType, hoursOperation: string[]): Promise<boolean> {
        return this.callableService.runCallableResult<UpsertHoursOperationPayload, boolean>("upsert-business-hours-operation", {
            groupId,
            businessId,
            hoursOperation
        });
    }

    public upsertDeliveryHoursOperation(groupId: string, businessId: BusinessType, hoursOperation: string[]): Promise<boolean> {
        return this.callableService.runCallableResult<UpsertHoursOperationPayload, boolean>("upsert-delivery-hours-operation", {
            groupId,
            businessId,
            hoursOperation
        });
    }

    /**
     * Obtiene el Profile actual del usuario
     * @param {User} user 
     * @param {number} profileIndex 
     * @return {Profile}
     */
    private currentProfileFromIndex(user: User, profileIndex: number): Profile {
        if (!isNil(user)) {
            const profiles = user.profiles;
            const length = profiles.length;

            if (profileIndex < 0 || profileIndex >= length) {
                profileIndex = 0;
            }

            return parseProfile(profiles[profileIndex]);
        }

        return;
    }

    /**
     * @param {string} businessId 
     * @return {Observable<Business>}
     */
    private getBusiness$(businessId: string): Observable<Business> {
        return this.fsService.doc$<Business>(this.path.business(businessId)).pipe(
            catchError(handleError$())
        )
    }
}
