// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .sidemenu-header-container {
  position: relative;
}
:host .sidemenu-header-container .sidemenu-header-avatar {
  margin: 16px auto 5px auto;
  background-image: url('icon.png');
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 12px;
}
:host .sidemenu-header-container .sidemenu-header-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}
:host .sidemenu-header-container .sidemenu-header-info ion-label {
  font-size: 12px;
  font-weight: 600;
  margin: 2px 3px;
}
:host .sidemenu-header-container .sidemenu-header-info ion-label.user-name {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/my-side-menu-header/my-side-menu-header.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEI;EACE,0BAAA;EACA,iCAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AADN;AAGM;EACE,eAAA;EACA,gBAAA;EACA,eAAA;AADR;AAIM;EACE,eAAA;AAFR","sourcesContent":[":host {\n  .sidemenu-header-container {\n    position:relative;\n\n    .sidemenu-header-avatar {\n      margin: 16px auto 5px auto;\n      background-image:url('../../../assets/imgs/icon.png');\n      background-size: cover;\n      height: 80px;\n      width: 80px;\n      border-radius: 12px;\n    }\n\n    .sidemenu-header-info {\n      display: flex;\n      align-items: center;\n      flex-direction: column;\n\n      ion-label {\n        font-size: 12px;\n        font-weight: 600;\n        margin: 2px 3px;\n      }\n  \n      ion-label.user-name {\n        font-size: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
