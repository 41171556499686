import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'my-new',
  templateUrl: 'my-new.html',
  styleUrls: ['./my-new.scss'],
  standalone: true,
  imports: [
    NgIf
  ]
})
export class MyNew implements OnInit {
  @Input('is-light-color') isLightColor = false;
  @Input() count = 0;
  
  constructor() {}

  ngOnInit(): void {
  }

}
