import { NgModule } from '@angular/core';
import { PreloadAllModules, NoPreloading, RouterModule, Routes } from '@angular/router';
import { authorizatedGuard } from '@guards/authorized-guard';
import { I18NResolver } from '@resolvers/i18n-resolver';

const routes: Routes = [
  { path: 'login', resolve: { lang: I18NResolver }, loadChildren: () => import('./pages/auth/auth.module').then(m => m.MyAuthModule) },
  { path: 'home/', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'products/', redirectTo: 'products', pathMatch: 'full' },
  { path: 'products',  resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule) },
  { path: 'automatic-optin/', redirectTo: 'automatic-optin', pathMatch: 'full' },
  { path: 'automatic-optin',  resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/automatic-optin/automatic-optin.module').then( m => m.AutomaticOptinModule) },
  { path: 'social-networks/', redirectTo: 'social-networks', pathMatch: 'full' },
  { path: 'social-networks',  resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/social-networks/social-networks.module').then( m => m.SocialNetworksModule) },
  { path: 'image-upload/', redirectTo: 'image-upload', pathMatch: 'full' },
  { path: 'image-upload',  resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/image-upload/image-upload.module').then( m => m.ImageUploadModule) },
  { path: 'templates/', redirectTo: 'templates', pathMatch: 'full' },
  { path: 'templates', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/templates/templates.module').then( m => m.TemplatesPageModule) },
  { path: 'categories/', redirectTo: 'categories', pathMatch: 'full' },
  { path: 'categories', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule) },
  { path: 'config/', redirectTo: 'config', pathMatch: 'full' },
  { path: 'config', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule) },
  { path: 'promos/', redirectTo: 'promos', pathMatch: 'full' },
  { path: 'promos', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/promos/promos.module').then( m => m.PromosPageModule) },
  { path: 'dzones/', redirectTo: 'dzones', pathMatch: 'full' },
  { path: 'dzones', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/dzones/dzones.module').then( m => m.DZonesPageModule) },
  { path: 'admin/', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'admin', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule) },  
  { path: 'reports/', redirectTo: 'reports', pathMatch: 'full' },
  { path: 'reports', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsModule) },
  { path: 'sysadmin/', redirectTo: 'sysadmin', pathMatch: 'full' },
  { path: 'sysadmin', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/sysadmin/sysadmin.module').then( m => m.SysAdminModule) },
  { path: 'groups/', redirectTo: 'groups', pathMatch: 'full' },
  { path: 'groups', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule) },
  { path: 'fidelity/', redirectTo: 'fidelity', pathMatch: 'full' },
  { path: 'fidelity', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/fidelity/fidelity.module').then( m => m.FidelityPageModule) },
  { path: 'referrals/', redirectTo: 'referrals', pathMatch: 'full' },
  { path: 'referrals', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/referrals/referrals.module').then( m => m.ReferralsPageModule) },
  { path: 'codes/', redirectTo: 'codes', pathMatch: 'full' },
  { path: 'codes', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/codes/codes.module').then( m => m.CodesPageModule) },
  { path: 'hours-operation/', redirectTo: 'hours-operation', pathMatch: 'full' },
  { path: 'hours-operation', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/hours-operation/hours-operation.module').then( m => m.HoursOperationPageModule) },
  { path: 'hours-operation-business/', redirectTo: 'hours-operation-business', pathMatch: 'full' },
  { path: 'hours-operation-business', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/hours-operation-business/hours-operation-business.module').then( m => m.HoursOperationBusinessModule) },
  { path: 'hours-operation-delivery/', redirectTo: 'hours-operation-delivery', pathMatch: 'full' },
  { path: 'hours-operation-delivery', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/hours-operation-delivery/hours-operation-delivery.module').then( m => m.HoursOperationDeliveryModule) },
  { path: 'special-closings/', redirectTo: 'special-closings', pathMatch: 'full' },
  { path: 'special-closings', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/special-closings/special-closings.module').then( m => m.SpecialClosingsModule) },
  { path: 'campaigns/', redirectTo: 'campaigns', pathMatch: 'full' },
  { path: 'campaigns', resolve: { lang: I18NResolver }, canActivate: [authorizatedGuard()], loadChildren: () => import('./pages/campaigns/campaigns.module').then( m => m.CampaignsModule) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      enableTracing: false, // <-- cambiar a true para: debugging purposes only
      //reloadingStrategy: PreloadAllModules,
      preloadingStrategy: NoPreloading
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
