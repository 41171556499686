// Resolve Guard service
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { I18NService } from '@services/i18n-service/i18n-service';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class I18NResolver implements Resolve<boolean> {
    constructor(
        private i18nService: I18NService) { 
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.i18nService.isDidLoad$();
    }
}