import { Country } from "@model/types/country";
import { es } from "date-fns/locale";

export const DEFAULT_COUNTRY_CODE = Country.URUGUAY;
export const DEFAULT_TZ = "America/Montevideo";
export const DEFAULT_CURRENCY_SYMBOL = "$";
export const DEFAULT_LOCALE = es;
export const DEFAULT_FORMAT_DATE_OBJECT = {
    Format_ddMMMMddMMMM: "{0} de {1} al {2} de {3}",
    Capitalize_ddMMMMddMMMM: [2, 6],
    Format_ddddMMMM: "{0} al {1} de {2}",
    Capitalize_ddddMMMM: [4],
    EEEEddMMMM: "EEEE dd 'de' MMMM",
    Capitalize_EEEEddMMMM: [0, 3],
    ddMMMM: "dd 'de' MMMM",
    Capitalize_ddMMMM: [2],
    MMyyyy: 'MM-yyyy',
    ddMM: "dd-MM",
    ddMMHHmmss: 'dd/MM HH:mm:ss',
    ddMMyyyy: 'dd-MM-yyyy',
    ddMMyyyyHHmm: 'dd/MM/yyyy HH:mm',
    ddMMyyyyHHmmss: 'dd/MM/yyyy - HH:mm:ss',
    EEEEd: 'EEEE d'
}

export const CURRENCY_SYMBOL_PESOS = "$";
export const CURRENCY_SYMBOL_GUARANIES = "Gs.";
export const CURRENCY_SYMBOL_EUROS = "€";
export const CURRENCY_SYMBOL_DOLARES = "US$ ";
export const CURRENCY_SYMBOL_QUETZAL = "Q";
export const CURRENCY_SYMBOLS = [CURRENCY_SYMBOL_PESOS, CURRENCY_SYMBOL_GUARANIES, CURRENCY_SYMBOL_EUROS, CURRENCY_SYMBOL_DOLARES, CURRENCY_SYMBOL_QUETZAL];

export const CURRENCY_SYMBOL_MAP = {
    [Country.URUGUAY]: CURRENCY_SYMBOL_PESOS,
    [Country.PARAGUAY]: CURRENCY_SYMBOL_GUARANIES,
    [Country.ESPANA]: CURRENCY_SYMBOL_EUROS,
    [Country.GUATEMALA]: CURRENCY_SYMBOL_QUETZAL
};

export const LOCALE_MAP = {
    [Country.URUGUAY]: DEFAULT_LOCALE,
    [Country.PARAGUAY]: DEFAULT_LOCALE,
    [Country.ESPANA]: DEFAULT_LOCALE,
    [Country.GUATEMALA]: DEFAULT_LOCALE
};

export const DECIMAL_SEPARATOR_MAP = {
    [Country.URUGUAY]: ",",
    [Country.PARAGUAY]: ",",
    [Country.ESPANA]: ",",
    [Country.GUATEMALA]: "."
};

export const THOUSANDS_SEPARATOR_MAP = {
    [Country.URUGUAY]: ".",
    [Country.PARAGUAY]: ".",
    [Country.ESPANA]: ".",
    [Country.GUATEMALA]: ","
};


/**
 * La lógica es, si quiero obtener el formato de fecha para por ejemplo "día mes hora min seg"
 * utilizo la clave ddMMHHmmss en el país correspondiente, entonces cada entrada va a tener el formato correspondiente a cada país
 * Para el caso del ejemplo, en algún lugar puede ser  'dd/MM HH:mm:ss' y en otro  'dd-MM HH:mm:ss'
 */
export const FORMAT_DATE_MAP = {
    [Country.URUGUAY]: DEFAULT_FORMAT_DATE_OBJECT,
    [Country.PARAGUAY]: DEFAULT_FORMAT_DATE_OBJECT,
    [Country.ESPANA]: DEFAULT_FORMAT_DATE_OBJECT,
    [Country.GUATEMALA]: DEFAULT_FORMAT_DATE_OBJECT
};
