import {
  DocumentReference as FDocumentReference,
  GeoPoint as FGeoPoint,
  Timestamp as FTimestamp
} from 'firebase/firestore';


export type DocumentReference = FDocumentReference;
export type Timestamp = FTimestamp;
export type TimestampLike = {
  nanoseconds: number;
  seconds: number;
} | {
  _nanoseconds: number;
  _seconds: number;
};

export type GeoPoint = FGeoPoint;

export function fromDate(date: Date): Timestamp {
  return FTimestamp.fromDate(date);
}

export function fromTimestampLike(obj: TimestampLike): Timestamp {
  const objAny: any = obj;

  if(objAny.nanoseconds !== undefined && objAny.seconds !== undefined) {
    return new FTimestamp(objAny.seconds, objAny.nanoseconds);
  }

  return new FTimestamp(objAny._seconds, objAny._nanoseconds);
}

export function isTimestampLike(obj: TimestampLike): obj is TimestampLike {
  const objAny: any = obj;

  if((objAny.nanoseconds !== undefined && objAny.seconds !== undefined) || objAny._nanoseconds !== undefined && objAny._seconds !== undefined) {
    return true;
  }

  return false;
}