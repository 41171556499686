import { FieldPath, OrderByDirection, QueryConstraint, WhereFilterOp, limit, orderBy, startAfter, where } from "firebase/firestore";

export type QueryFn = (queryBuilder: QueryBuilder) => QueryBuilder;

export class QueryBuilder {
    private queryConstraint: QueryConstraint[] = [];

    public chain(qb: QueryBuilder): QueryBuilder {
        this.queryConstraint.push(...qb.getQueryConstraint());

        return this;
    }

    public append(queryConstraintOrQueryFn: QueryConstraint[] | QueryFn): QueryBuilder {
        let appendQueryConstranint: QueryConstraint[];

        if (typeof queryConstraintOrQueryFn === 'function') {
            appendQueryConstranint = callQueryFn(queryConstraintOrQueryFn);
        } else {
            appendQueryConstranint = queryConstraintOrQueryFn;
        }

        this.queryConstraint.push(...appendQueryConstranint);

        return this;
    }

    public where(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): QueryBuilder {
        this.queryConstraint.push(
            where(fieldPath, opStr, value)
        );

        return this;
    }

    public orderBy(fieldPath: string | FieldPath, directionStr?: OrderByDirection): QueryBuilder {
        this.queryConstraint.push(
            orderBy(fieldPath, directionStr)
        );

        return this;
    }

    public getQueryConstraint(): QueryConstraint[] {
        return this.queryConstraint;
    }

    public startAfter(...fieldValues: unknown[]): QueryBuilder {
        this.queryConstraint.push(
            startAfter(...fieldValues)
        );

        return this;
    }

    public limit(_limit: number): QueryBuilder {
        this.queryConstraint.push(
            limit(_limit)
        );

        return this;
    }
}

export function callQueryFn(queryFn?: QueryFn): QueryConstraint[] {
    if (queryFn) {
        return queryFn(new QueryBuilder()).getQueryConstraint();
    }

    return [];
}