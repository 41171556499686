import { Observable, ReplaySubject } from "rxjs";
import { share } from "rxjs/operators";

export function doShare$<T>(source$: Observable<T>): Observable<T> {
    return source$.pipe(
        share({ connector: () => new ReplaySubject(1) })
    )
}

export function doShare<T>() {
    return (source$: Observable<T>) => source$.pipe(share({ connector: () => new ReplaySubject(1) }));
}

export function doShareIf<T>(source$: Observable<T>, condition: boolean): Observable<T> {
    return condition ? doShare$(source$) : source$;
}