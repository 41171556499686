import { Injectable } from '@angular/core';
import { Country } from '@model/types/country';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { I18NLang, E_I18NResource } from './types';

@Injectable({
  providedIn: 'root'
})
export class I18NService {
  private messages: I18NLang;
  private country: Country;
  private didLoad$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  // public load(country: string): Promise<void> {
  //   this.didLoad = new Promise((resolve, reject) => {
  //     if(country !== this.country) {
  //       import(`../../../assets/i18n/messages.${country.toLowerCase()}.json`).then(m => {
  //         this.messages = m;
  //         resolve();
  //       }).catch(e => {
  //         console.log(e);
  //         reject();
  //       })
  //     }
  //   });
    
  //   return this.didLoad;
  // }

  public async load(country: string): Promise<void> {
    try {
      if(country !== this.country) {
        this.didLoad$.next(false);
        this.messages = await import(`../../../assets/i18n/messages.${country.toLowerCase()}.json`);
        this.didLoad$.next(true);
      }
    } catch(e) {
      console.log(e);
    }
  }

  public getResource<T extends E_I18NResource>(resourceName: T): I18NLang[T] {
    return this.messages[resourceName];
  }

  public getLang(): I18NLang {
    return this.messages;
  }

  public isDidLoad$(): Observable<boolean> {
    return this.didLoad$.pipe(
      filter(didLoad => didLoad),
      first(),
    );
  }
}