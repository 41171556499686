
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

declare var Connection;

@Injectable({
    providedIn: 'root'
})
export class ConnectivityService {

    onDevice: boolean;

    constructor(
        private platform: Platform,
        private network: Network) {
        this.onDevice = this.platform.is('cordova');
    }

    public isOnline(): boolean {
        if (this.onDevice && this.network.type) {
            return this.network.type !== Connection.NONE;
        } else {
            return navigator.onLine;
        }
    }

    public isOffline(): boolean {
        if (this.onDevice && this.network.type) {
            return this.network.type === Connection.NONE;
        } else {
            return !navigator.onLine;
        }
    }

    public isOnline$(): Observable<boolean> {
        return merge(
            of(navigator.onLine),
            fromEvent(window, 'offline').pipe(map(e => false)),
            fromEvent(window, 'online').pipe(map(e => true))
        );
    }
}
