export enum ErrorType { ResetPassword, Login, Callable, Generic }

//Observables
import { throwError } from 'rxjs';

interface IMyError {
  showInGUI: boolean;
  message: string;
  code: string;
  details: any;
}

export class MyError {
  message: string = undefined;
  code: string = undefined;
  details: any = undefined;
  showInGUI: boolean = false;

  constructor(error: Partial<IMyError>, errorType: ErrorType = ErrorType.Generic) {
    this.code = error.code;

    const mapMessage = codeErrorToMessage(error.code, errorType);
    this.message = mapMessage ?? error.message;
    this.details = error.details;
    this.showInGUI = error.showInGUI ?? false;
  }
}

export function handleError(errorType: ErrorType = ErrorType.Generic) {
  return (error) => {
    throw new MyError(error, errorType);
  };
}

export function handleError$(errorType: ErrorType = ErrorType.Generic) {
  return error => {
    return throwError(() => new MyError(error, errorType));
  };
}

function codeErrorToMessage(code: string, errorType: ErrorType = ErrorType.Generic): string | undefined {
  const mapMessage = {
    [ErrorType.Generic]: {
      'auth/network-request-failed': 'No se ha detectado una conexión de red, revise su conexión e intente nuevamente',
      'auth/weak-password': 'La contraseña es muy débil',
      'auth/wrong-password': 'Usuario o contraseña incorrectos',
      'auth/invalid-email': 'Email inválido',
      'auth/user-disabled': 'Email inhabilitado',
      'auth/invalid-custom-token': 'Sesión expirada, para ingresar a su cuenta solicite un nuevo enlace enviando HOLA desde Whatsapp',
      'auth/user-not-found': 'Usuario o contraseña incorrectos',
      'auth/email-already-exists': 'El email está siendo utilizado por otra cuenta',
      'auth/email-already-in-use': 'Usuario ya registrado',
      'auth/account-exists-with-different-credential': 'El email está siendo utilizado por otra cuenta',
      'permission-denied': 'Permisos insuficientes',
      'auth/requires-recent-login': 'Requiere iniciar sesión nuevamente',
      'auth/too-many-requests': 'Demasiados intentos',
    },
    [ErrorType.ResetPassword]: {
      'auth/user-not-found': 'Email no encontrado'
    },
    [ErrorType.Login]: {
      'auth/user-not-found': 'Usuario o contraseña incorrectos',
      'auth/invalid-custom-token': 'Sesión expirada, para ingresar a su cuenta solicite un nuevo enlace enviando HOLA desde Whatsapp',
      'auth/wrong-password': 'Usuario o contraseña incorrectos'
    }
  }

  const messages = mapMessage[errorType] ?? {};

  return messages[code];
}

export function errorToString(error): string {
  let msg: string;

  if (error.toString) {
    msg = error.toString();
  } else if (error.message) {
    msg = error.message;
  } else {
    msg = error.stack;
  }

  return msg;
}