import { ExtendedAddress } from "@model/address";
import { Profile } from "@model/users/profile";

/**
 * 
 * @param {string} profile cadena en formato groupId#businessId
 */
export function parseProfile(profile: string): Profile {
    const [groupId, businessId] = profile.split("#");
    return { groupId, businessId: businessId ?? null };
}

export function extendedAddressToString(address: ExtendedAddress): string {
    const buffer: string[] = [];

    buffer.push(address.addressLine1);
    buffer.push(address.no);

    if(address.apt) {
      buffer.push(`apto. ${address.apt}`);
    }

    if(address.addressLine2) {
        buffer.push(`esquina ${address.addressLine2}`);
    }

    if(address.neighborhood) {
        buffer.push(`,${address.neighborhood}`);
    }

    if(address.city) {
        buffer.push(`,${address.city}`);
    }

    return buffer.join(' ');
}