export interface Roles {
    adminGroup: boolean;
    adminBusiness: boolean;
    operator: boolean;
    sysAdmin?: boolean;
}

export function defaultRoles() {
    return {
        adminGroup: false,
        adminBusiness: false,
        operator: true,
        sysAdmin: false
    }
}