import { Injectable } from '@angular/core';

import { isNil } from '@shared/lodash';
import { OnCallService } from './oncall-service';
import { RestService } from './rest-service';
import { CallableOptions, CallableResult, E_CallableOptionsMethod } from './types';

@Injectable({
    providedIn: 'root'
})
export class CallableService {
    constructor(
        private onCallService: OnCallService, 
        private restService: RestService) {}


    public runCallable<Req = any, Res = any>(callable: string, data: Req, options: Partial<CallableOptions> = {}): Promise<CallableResult<Res>> {
        const _method = !isNil(options.method) ? options.method : E_CallableOptionsMethod.OnCall;
        if(_method === E_CallableOptionsMethod.OnCall) {
            return this.onCallService.runCallable(callable, data, options);
        }

        return this.restService.runCallable(callable, data, options);
    }

    public runCallableResult<Req = any, Res = any>(callable: string, data: Req, options: Partial<CallableOptions> = {}): Promise<Res> {
        return this.runCallable<Req, Res>(callable, data, options).then(callableResult => callableResult.result);
    }
}
