import { Component } from '@angular/core';
import { DEFAULT_COUNTRY_CODE } from '@config/parameters';
import { Platform } from '@ionic/angular';
import { setupConfig } from '@ionic/core';
import { AuthService } from '@services/auth-service/auth-service';
import { GlobalErrorLoggerService } from '@services/global-error-service/global-error-logger-service';
import { I18NService } from '@services/i18n-service/i18n-service';
import { LoggerService } from '@services/logger-service/logger-service';
import { PushNotificationService } from '@services/push-notification-service/push-notification-service';
import { PwaService } from '@services/pwa-service/pwa-service';
// import { register } from 'swiper/element/bundle';
import { register } from 'swiper/element';

// swiper
// https://ionicframework.com/docs/angular/slides
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class DiHolaBackendComponent {
  constructor(
    private platform: Platform,
    private pushNotificationService: PushNotificationService,
    private authService: AuthService,
    private globalErrorLoggerService: GlobalErrorLoggerService,
    private pwaService: PwaService,
    private loggerService: LoggerService,
    private i18n: I18NService
  ) {
    this.initializeApp();
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      if (!this.platform.is('mobile')) {
        // quitar la animación entre página y página
        setupConfig({ animated: false });
      }

      this.authService.getUserLoggedIn$(true).subscribe(backendUser => {
        if(backendUser) {
          this.i18n.load(backendUser.lang ?? DEFAULT_COUNTRY_CODE);
          this.pushNotificationService.init();
          this.pwaService.init();
          this.globalErrorLoggerService.log();
          this.loggerService.didReload();
        } else {
          this.i18n.load(DEFAULT_COUNTRY_CODE);
        }
      });
    });
  }
}
