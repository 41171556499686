import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Input, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IonAccordionGroup, IonicModule, MenuController, NavController } from '@ionic/angular';

//services
import { AuthService } from '@services/auth-service/auth-service';

//model
import { User } from '@model/users/user';

//gui
import { GUI } from '@gui/gui';
import { unsubscribe } from '@shared/subscription';
import { BusinessService } from '@services/business-service/business-service';
import { Business } from '@model/business/business';
import { MySideMenuHeader } from '@components/my-side-menu-header/my-side-menu-header';
import { MyNew } from '@components/my-new/my-new';

@Component({
  selector: 'my-side-menu-desktop',
  templateUrl: './my-side-menu-desktop.html',
  styleUrls: ['./my-side-menu-desktop.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FontAwesomeModule,
    MySideMenuHeader,
    MyNew
  ]
})
export class MySideMenuDesktop implements OnInit, OnDestroy {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  @Input() contentId: string;

  user: User;
  business: Business;

  subscriptions = [];

  constructor(
    private gui: GUI,
    private menu: MenuController,
    private navCtrl: NavController,
    private businessService: BusinessService,
    private authService: AuthService) { }

  ngOnInit() {
    this.menu.enable(true, 'custom');
    const user$ = this.authService.getUserLoggedIn$(true, true);

    this.subscriptions.push(
      user$.subscribe({
        next: user => {
          this.user = user;
        },
        error: err => {
          if(err?.code === 'permission-denied') {
            this.doExit();
          }
        }
      })
    );

    this.subscriptions.push(
      this.businessService.currentBusiness$(user$).subscribe(business => {
        this.business = business;
      })
    );
  }

  onClickGoToPage(page: string) {
    this.navCtrl.navigateRoot(page);
    this.closeLeftMenu();
  }

  onClickEnter() {
    this.navCtrl.navigateRoot('login');
  }

  onClickExit() {
    this.gui.showConfirmMessage(
      'CERRAR SESIÓN',
      '¿Desea cerrar sesión?',
      () => {
        this.gui.presentCircleLoadingAndWait(
          async () => {
            this.doExit();
          }
        );
      });
  }

  private async doExit() {
    await this.authService.signOut();
    this.navCtrl.navigateRoot('/login');
    this.closeLeftMenu();
  }

  closeLeftMenu() {
    this.menu.close('custom');
    this.closeAccordion();
  }

  closeAccordion() {
    if (this.accordionGroup) {
      this.accordionGroup.value = undefined;
    }
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions);
  }
}
