import { Injectable } from '@angular/core';

//firebase
import { Auth, UserCredential, User as FUser, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, sendEmailVerification, signOut, onAuthStateChanged, getAuth } from 'firebase/auth';
import { authState } from 'rxfire/auth';

//Observables
import { Observable, of, from, firstValueFrom } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

//model
import { Account } from '@model/users/account';
import { User } from '@model/users/user';
import { MyError, handleError, ErrorType } from '@model/error';

//services
import { FirestoreService } from '@services/firestore-service/firestore-service';
import { PathService } from '@services/path-service/path-service';
import { defaultRoles } from '@model/users/roles';
import { doShareIf } from '@shared/do-share';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn: boolean = undefined;
  private auth: Auth;

  constructor(
    private pathService: PathService,
    private fsService: FirestoreService) {
      this.auth = getAuth();
    this.subscribeIsLoggedIn();
  }

  public login(account: Account): Promise<User> {
    return this.processUserCredential(
      signInWithEmailAndPassword(this.auth, account.email, account.password),
      true
    ).catch(handleError());
  }

  public async loginCustomToken(token: string): Promise<User> {
    const user = await this.processUserCredential(
      signInWithCustomToken(this.auth, token),
      true
    );

    return user;
  }

  public resetPassword(email: string): Promise<any> {
    return sendPasswordResetEmail(this.auth, email)
      .catch(handleError(ErrorType.ResetPassword));
  }

  public sendEmailVerification(): Promise<any> {
    return firstValueFrom(this.authState()).then(fbuser => {
      if (fbuser) {
        return sendEmailVerification(fbuser).catch(handleError());
      }

      return;
    });
  }

  public isLoggedIn(): Promise<boolean> {
    if (this._isLoggedIn === undefined) {
      return new Promise((resolve, reject) => {
        authState(this.auth).pipe(first()).subscribe(res => {
          const _isLoggedIn = res && res.uid ? true : false;
          return resolve(_isLoggedIn);
        }, error => {
          resolve(false);
        });
      });
    } else {
      return Promise.resolve(this._isLoggedIn);
    }
  }

  public getUserLoggedIn$(fullInfo: boolean = false, share: boolean = false): Observable<User> {
    let result$: Observable<User>;

    if (fullInfo) {
      result$ = this.authState().pipe(
        switchMap(user => user && user.uid ? this.fsService.docWithId$<User>(this.pathService.user(user.uid)) : of<User>(null))
      );
    } else {
      result$ = this.authState().pipe(
        map(user => user && user.uid ? this.toUser(user) : null)
      );
    }

    return doShareIf(result$, share);
  }

  public getUserLoggedIn(fullInfo: boolean = false): Promise<User> {
    if (fullInfo) {
      return firstValueFrom(
        this.authState().pipe(
          switchMap(user => user && user.uid ? this.fsService.docWithId<User>(this.pathService.user(user.uid)) : of<User>(null))
        )
      ).catch(handleError());
    }

    return firstValueFrom(
      this.authState().pipe(
        map(user => user && user.uid ? this.toUser(user) : null)
      )
    ).catch(handleError());
  }

  public getUserLoggedInReload$(): Observable<User> {
    return this.authState().pipe(
      switchMap(fbuser => {
        if (fbuser && fbuser.uid) {
          return from(
            fbuser.reload().then(() => {
              return fbuser;
            })
          );
        } else {
          return of(null);
        }
      }),
      map(fbuser => fbuser && fbuser.uid ? this.toUser(fbuser) : null)
    );
  }

  public getUserLoggedInReload(): Promise<User> {
    return firstValueFrom(this.getUserLoggedInReload$().pipe(first()))
      .catch(handleError());
  }

  public signOut(): Promise<void> {
    return signOut(this.auth)
      .catch(_ => {
        return;
      });
  }

  public authState(): Observable<FUser | null> {
    return from(this.auth.authStateReady()).pipe(
      switchMap(() => authState(this.auth))
    );
  }

  public getIdToken(forceRefresh?: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let token: string;

      const unsubscribe = onAuthStateChanged(this.auth, async (currentUser) => {
        if (currentUser) {
          token = await currentUser.getIdToken(forceRefresh);
        }

        unsubscribe();
        resolve(token);
      }, err => {
        unsubscribe();
        resolve(null);
      });
    });
  }

  public getUser$(uid: string): Observable<User> {
    return this.fsService.docWithId$(this.pathService.user(uid));
  }

  // privates
  private processUserCredential(promiseUserCredential: Promise<UserCredential>, throwError: boolean): Promise<User> {
    return promiseUserCredential.then(userCredential => {
      const fbuser = userCredential.user;
      return this.getUserOnce(fbuser.uid).then(user => {
        if (user) {
          return user;
        } else {
          this.signOut();
          throw new MyError({ code: 'auth/user-not-found' });
        }
      });
    })
      .catch(err => {
        return this.signOut().then(() => {
          if (throwError) {
            throw err;
          } else {
            return err;
          }
        });
      })
      .catch(handleError(ErrorType.Login));
  }

  private getUserOnce(uid: string): Promise<User> {
    return this.fsService.docOnce<User>(this.pathService.user(uid));
  }

  private subscribeIsLoggedIn() {
    this.authState().subscribe(res => {
      this._isLoggedIn = res && res.uid ? true : false;
    }, err => {
    }, () => {
    });
  }

  private toUser(fuser: FUser): User {
    return {
      id: fuser.uid,
      email: fuser.email,
      profiles: [],
      roles: defaultRoles(),
      emailVerified: fuser.emailVerified
    }
  }
}
