import { Inject, Injectable } from '@angular/core';
import { LOG_TOKEN } from '@config/config';
import { Collections } from '@config/constants';
import { ConnectivityService } from '@services/connectivity-service/connectivity-service';
import { FirestoreService } from '@services/firestore-service/firestore-service';
import { StorageService } from '@services/storage-service/storage-service';
import { isNil } from '@shared/lodash';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorLoggerService {
  constructor(
    @Inject(LOG_TOKEN) private logToken,
    private storageService: StorageService,
    private connectivityService: ConnectivityService,
    private fsService: FirestoreService) { }

  public async log(): Promise<void> {
    /**
     * Intentamos loguear los mensajes en el servidor
     */

    if (this.connectivityService.isOnline()) {
      // obtenemos el objeto a loguar
      const errorObj = await this.storageService.get(this.logToken);
      
      if(!isNil(errorObj)) {
        await this.fsService.add(Collections.Errors, {
          ...errorObj
        }, true);
  
        await this.storageService.remove(this.logToken);
      }
    }
  }
}
