import { Injectable } from '@angular/core';
import { Collections } from '@config/constants';
import { User } from '@model/users/user';
import { AuthService } from '@services/auth-service/auth-service';
import { LogService } from '@services/log-service/log-service';


@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private didLogReload = false;
  constructor(
    private authService: AuthService,
    private logService: LogService) {
  }

  public async didReload(): Promise<void> {
    try {
      if (!this.didLogReload) {
        console.log("Recargó página...");
        this.didLogReload = true;
        const user = await this.authService.getUserLoggedIn(true);

        if (user?.log?.enabled) {
          return this.logService.log(`Recargó página ${user.id!} ${user.email}`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public log(message: string): Promise<void> {
    try {
      console.log(message);
      return this.logService.log(message);
    } catch (e) {
      console.log(`Error en LoggerService (log) `, e);
    }
  }
}
