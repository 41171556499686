import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from "@environments/environment";
import { AuthService } from '@services/auth-service/auth-service';
import { POS_DOMAIN, WEB_PUSH_TAG_NAME } from '@config/config';

declare var OneSignal;

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    private initializated = false;

    constructor(
        @Inject(POS_DOMAIN) private posDomain: string[],
        @Inject(WEB_PUSH_TAG_NAME) private webPushTagName: string,
        private authService: AuthService,
        private platform: Platform) { }

    init() {
        if (this.canInstallPushNotification()) {
            OneSignal.push(function () {
                /* Init OneSignal */
                OneSignal.SERVICE_WORKER_PARAM = { scope: '/assets/sw/onesignal/' };
                OneSignal.SERVICE_WORKER_PATH = 'assets/sw/onesignal/OneSignalSDKWorker.js'
                OneSignal.SERVICE_WORKER_UPDATER_PATH = 'assets/sw/onesignal/OneSignalSDKUpdaterWorker.js'
                OneSignal.init({
                    appId: environment.webPushAppId,
                    safari_web_id: "",
                    autoRegister: false,
                    persistNotification: false,
                    notificationClickHandlerMatch: 'origin',
                    notificationClickHandlerAction: 'focus',
                    notifyButton: {
                        enable: true,
                        displayPredicate: function () {
                            return OneSignal.isPushNotificationsEnabled()
                                .then(function (isPushEnabled) {
                                    /* The user is subscribed, so we want to return "false" to hide the Subscription Bell */
                                    return !isPushEnabled;
                                });
                        },
                    },
                    welcomeNotification: {
                        "title": "DiHola",
                        "message": "Notificaciones de pedidos activadas!",
                        // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
                    },
                    promptOptions: {
                        /* actionMessage limited to 90 characters */
                        actionMessage: "¿Premitir recibir notificaciones de los pedidos entrantes?",
                        /* acceptButtonText limited to 15 characters */
                        acceptButtonText: "PERMITIR",
                        /* cancelButtonText limited to 15 characters */
                        cancelButtonText: "NO GRACIAS"
                    }
                });

                /* Displays the Slide Prompt to prompt users to subscribe.
                This slides down from the top of the screen (or up from the bottom on mobile) */
                OneSignal.showHttpPrompt();
            });

            this.initializated = true;

            if (!environment.production) {
                OneSignal.isPushNotificationsEnabled().then((isPushEnabled) => {
                    if(isPushEnabled) {
                        OneSignal.sendSelfNotification();
                    }
                });
            }

            this.authService.getUserLoggedIn$(true).subscribe(backendUser => {
                if (backendUser && backendUser.webPushEnabled) {
                    this.enable(backendUser.id);
                } else {
                    this.disable();
                }
            });
        }
    }

    enable(backendUserId: string) {
        /* You can call OneSignal.push(["sendTag", "key", "value"]) anytime after var OneSignal = OneSignal || [];; you do not have to wait until the user registers. Once the user registers, the tags will automatically be sent to our server as long as the page session is the same (i.e. the user has not navigated to another page).*/
        OneSignal.push(["sendTag", this.webPushTagName, backendUserId]);
    }

    disable() {
        if(OneSignal && OneSignal.deleteTag) {
            OneSignal.deleteTag(this.webPushTagName);
        }
    }

    private canInstallPushNotification(): boolean {
        return !this.initializated && 
        !this.platform.is('cordova') && 
        !window.location.hostname.includes('localhost') && 
        !this.posDomain.includes(window.location.hostname);
    }
}