import { ddMMyyyy_Value, DEFAULT_MIN_HOUR_VALUE } from "@config/config";
import { LocaleInfo } from "@model/types/locale";
import { MinHourInfo } from "@model/types/min-hour";
import { fromDate, fromTimestampLike, isTimestampLike, Timestamp, TimestampLike } from "@model/types/types";
import { addDays, addMinutes, format, subDays, formatDuration, intervalToDuration } from "date-fns";

import { formatInTimeZone, toZonedTime, fromZonedTime } from "date-fns-tz";
import { browserLocaleInfo, localeObj } from "./locale";
import { isNil } from "./lodash";


function getDatesByHourMin(hourMinLst: string[], mh: MinHourInfo): Date[] {
    const minHour = getMinHour(mh);
    const now = new Date();

    const dates = hourMinLst.map((hourMin) => {
        const inc = hourMin >= "00:00" && hourMin < minHour ? 1 : 0;
        const date = addDays(now, inc);
        date.setHours(getHourNumber(hourMin), getMinNumber(hourMin));

        return date;
    });

    return dates;
}

/**
 * Compara los string con formato HH:mm teniendo en cuenta que los días son de 07:00 a 07:00
 * Por ejemplo, las 22:00 es menor que las 00:30.
 * @param {string} hourMin1 
 * @param {string} hourMin2 
 * @param {MinHourInfo} mh
 * @return {number} 1 si hourMin1 > hourMin2, -1 si hourMin1 < hourMin2, 0 si hourMin1 === hourMin2
 * También se considera que si hourMin1 es hourMin entonces por estar del lado izquierdo se lo está utilizando como valor mínimo
 * y si hourMin2 es hourMin entonces se lo está utilizando como valor máximo.
 * Por ejemplo, en un horario de 07:00 a 07:00
 * hourMin1 = 05:00 
 * hourMin2 = 07:00 
 * Entonces en este caso hourMin2 es mayor que hourMin1 si el hourMin de MinHourInfo es 07:00, ya que hourMin2 es hourMin y está
 * del lado derecho.
 */
export function timeCompare(hourMin1: string, hourMin2: string, mh: MinHourInfo): number {
    if (hourMin1 === hourMin2) {
        return 0;
    }

    const minHour = getMinHour(mh);
    // En este caso hourMin1 es la hora minima y además sabemos que hourMin2 NO es la hora mínima sino hourMin1 y hourMin2 serían
    // iguales.
    if (hourMin1 === minHour) {
        return -1;
    }

    // Idem caso anterior.
    // En este caso hourMin2 está del lado derecho y es la hora mínima entonces en realidad hay que considerarla como la hora máxima
    // Y además sabemos que hourMin1 no es minHour
    if (hourMin2 === minHour) {
        return -1;
    }

    // En este caso hourMin1 y hourMin2 son 2 horarios cualquiera y por lo tanto lo transformamos a dates para compararlos.
    const [date1, date2] = getDatesByHourMin([hourMin1, hourMin2], mh);

    if (date1 > date2) {
        return 1;
    }

    if (date1 < date2) {
        return -1;
    }

    return 0;
}

/**
 * Formatea una fecha Date en HH:mm
 */
export function formatHHmm(dateOrMillis: Date | number): string {
    // esta colocado "es-ES" pues es la forma de obtener horas en formato 00:30 etc
    // si utilizo en-Us por ejemplo, la hora doce la muestra de la forma 24:00
    const date = typeof dateOrMillis === 'number' ? new Date(dateOrMillis) : dateOrMillis;
    return date.toLocaleString("es-ES", { hour: '2-digit', minute: '2-digit', hour12: false });
}

/**
 * Dada una fecha devuelve un string con el formato dado en el TZ local
 * @param {Date | Timestamp} someDate 
 * @param {string} formatStr 
 * @return {string}
 */
export function tzFormat(someDate: Date | Timestamp | TimestampLike | number, formatStr?: string, lc?: LocaleInfo): string {
    const date: Date = extractDate(someDate);

    formatStr = !isNil(formatStr) ? formatStr : ddMMyyyy_Value;
    lc = !isNil(lc) ? lc : browserLocaleInfo();
    return format(date, formatStr, { locale: localeObj(lc) });
}

/**
 * Devuelve un string del tipo es-ES
 * @return {string}
 */
export function getLocaleString(): string {
    return navigator.language;
}

/**
 * Dada una fecha con formato HH:mm devuelve el number asociado a la hora.
 * por ejemplo si el string es "22:03", devuelve el número 22
 */
export function getHourNumber(hourMin: string): number {
    return +(hourMin.substring(0, 2));
}

/**
 * Dada una fecha con formato HH:mm devuelve el number asociado a la hora.
 * por ejemplo si el string es "22:03", devuelve el número 3
 */
export function getMinNumber(hourMin: string): number {
    return +(hourMin.substring(3, 5));
}

/**
 * Incrementa a una entrada con formato HH:mm la cantidad de minutos indicados
 * y devuelve un string con formato HH:mm 
 */
export function incMinutes(hourMin: string, minutes: number): string {
    const now = new Date();
    now.setHours(getHourNumber(hourMin), getMinNumber(hourMin));
    const incremented = addMinutes(now, minutes);

    return formatHHmm(incremented);
}

export function compareTimestamp(t1: Timestamp, t2: Timestamp): number {
    const n1 = !isNil(t1) ? t1.toMillis() : 0;
    const n2 = !isNil(t2) ? t2.toMillis() : 0;

    if (n1 > n2) {
        return 1;
    }

    if (n1 < n2) {
        return -1;
    }

    return 0;
}


/**
 * Devuelve true sii el timestamp dado está expirado
 */
export function expiredTimestamp(timestamp: Timestamp): boolean {
    const now = Date.now();
    return serverDateToMillis(timestamp) < now;
}

/**
 * transforma una fecha obtenido desde el servidor a un número en millis en el timezone local
 */
export function serverDateToMillis(serverDate: Date | Timestamp): number {
    if (serverDate) {
        const _serverDate = serverDateToDate(serverDate);
        return _serverDate.getTime();
    }

    return 0;
}

/**
 * transforma una fecha obtenido desde el servidor a un Dateen el timezone local
 */
export function serverDateToLocalDate(serverDate: Date | Timestamp): Date {
    return new Date(serverDateToMillis(serverDate));
}

export function toTimestamp(date: Date): Timestamp {
    return fromDate(date)
}

/**
 * Devuelve un timepstamp de firestore a partir de un objeto 
 * @param {{nanoseconds: number, secods: number }} obj
 * @return {Timestamp}
 */
export function fromObject(obj: TimestampLike): Timestamp {
    return fromTimestampLike(obj);
}

export function isFromObject(obj: TimestampLike): obj is TimestampLike {
    return isTimestampLike(obj);
}

/**
 * @param serverDate 
 * @return {Date} 
 */
export function serverDateToDate(serverDate: Date | Timestamp | TimestampLike | string): Date {
    if(serverDate instanceof Date) {
        return serverDate;
    } 

    if(typeof serverDate === "string") {
        return new Date(serverDate);
    }

    let serverDateTimestamp: Timestamp;
    if(isFromObject(serverDate)) {
        serverDateTimestamp = fromObject(serverDate);
    } else {
        serverDateTimestamp = serverDate;
    }

    return serverDateTimestamp.toDate();
}

/**
 * Devuelve la fecha en formato ISO 8601 
 * @param {Date | Timestamp} someDate 
 * @return {string}
 */
export function toISOString(someDate: Date | Timestamp): string {
    const date = serverDateToDate(someDate);
    return date.toISOString();
}

/**
 * 
 * @param someUTCDate 
 * @param lc 
 * @return {string} 
 */
export function toLocalISO(someUTCDate: Date | Timestamp | string, lc?: LocaleInfo): string {
    const date = serverDateToDate(someUTCDate);
    lc = !isNil(lc) ? lc : browserLocaleInfo();
    return formatInTimeZone(date, lc.tz, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}

/**
 * Convierte una fecha local a un Date en UTC
 * @param {Date} someDate 
 * @param {LocaleInfo} lc 
 * @return {Date}
 */
export function toUTC(someDate: Date | Timestamp, lc?: LocaleInfo): Date {
    const date = serverDateToDate(someDate);
    lc = !isNil(lc) ? lc : browserLocaleInfo();
    return fromZonedTime(date, lc.tz);
}

/**
 * Devueve una fecha que es el dia de hoy menos @days
 */
export function daysAgo(days: number): Date {
    return subDays(new Date(), days);
}

/**
 * Devuelve una fecha aproximando al intervalo más cercano.
 * @param {number} interval 
 * @param {Date} someDate 
 * @return {Date} 
 */
export function nearestMinutes(interval: number, someDate: Date): Date {
    const roundedMinutes = Math.round(someDate.getMinutes() / interval) * interval;
    const someDateClone = new Date(someDate);
    someDateClone.setMinutes(roundedMinutes);
    someDateClone.setSeconds(0);
    someDateClone.setMilliseconds(0);

    return someDateClone;
}

export function nearestHHmm(interval: number, someDate: Date, lc: LocaleInfo): string {
    const nearest = nearestMinutes(interval, someDate);
    const nearestZonedTime = toZonedTime(nearest, lc.tz, { locale: localeObj(lc) });
    return formatHHmm(nearestZonedTime);
}

/**
 * Devuelve un código numerico a partir de una fecha o una fecha en millis
 * @param {number | Date | Timestamp} serverDate
 * @param {number} n la cantidad de digitos del código
 * @return {string}
 */
export function getCode(serverDate: number | Date | Timestamp, n?: number): string {
    let dateMillis: number;

    if (typeof serverDate === 'number') {
        dateMillis = serverDate;
    } else {
        const date = serverDateToDate(serverDate);
        dateMillis = date.getTime();
    }

    const code = `${dateMillis}`;
    let offset = n ? n : 5;
    const start = code.length - offset;
    const end = code.length;
    return code.substring(start, end);
}

export function extractDate(someDate: Date | Timestamp | TimestampLike | string | number): Date {
    if (someDate instanceof Date) {
        return someDate;
    }

    if ((someDate as any).toDate) {
        return (someDate as Timestamp).toDate()
    }

    if (typeof someDate === "string" || typeof someDate === "number") {
        return new Date(someDate);
    }

    return fromTimestampLike(someDate).toDate();
}

export function initialDay(someDate: Date | Timestamp | TimestampLike | string | number): Date {
    const date: Date = extractDate(someDate);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

/**
 * Devuelve la hora mínima que considera el sistema, sino devuelve una por defecto
 * @param {MinHourInfo} mh
 * @return {string} hora mínima que considera el sistema en formato HH:mm 
 */
export function getMinHour(mh: MinHourInfo): string {
    return mh.minHour ?? DEFAULT_MIN_HOUR_VALUE;
}

/**
 * Transforma milisegundos a horas devolviendo las horas con los decimales.
 * @param {number} milliseconds 
 * @return {number}
 */
export function millisecondsToHours(milliseconds: number): number {
    return milliseconds / (1000 * 60 * 60);
}

/**
 * Transforma minutos a horas devolviendo las horas con los decimales.
 * @param {number} milliseconds 
 * @return {number}
 */
export function minutesToHours(minutes: number): number {
    return minutes / 60;
}

/**
 * @param {Date | Timestamp} start 
 * @param {Date | Timestamp} end 
 * @return {string} 
 */
export function intervalToString(start: Date | Timestamp, end: Date | Timestamp): string {
    const startDate = serverDateToDate(start);
    const endDate = serverDateToDate(end);

    const duration = intervalToDuration({ start: startDate, end: endDate });
    const formatted = formatDuration(duration, { format: ['hours', 'minutes', 'seconds'] });

    return formatted
        .replace(' hours', 'hr')
        .replace(' hour', 'hr')
        .replace(' minutes', 'min')
        .replace(' minute', 'min')
        .replace(' seconds', 'seg')
        .replace(' second', 'seg');
}