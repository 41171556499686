import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Business } from '@model/business/business';
import { User } from '@model/users/user';


@Component({
  selector: 'my-side-menu-header',
  templateUrl: 'my-side-menu-header.html',
  styleUrls: ['./my-side-menu-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule
  ]
})
export class MySideMenuHeader {
  @Input() user: User;
  @Input() business: Business;

  constructor() {}
}
