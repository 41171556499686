import { environment } from '@environments/environment';

export function getEnvironment(): string {
    return environment.production ? "pro" : "dev";
}

export function isProduction(): boolean {
    return environment.production;
}

