import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private initializated = false;

  constructor(private swUpdate: SwUpdate) { 
  }

  public init() {
    if(this.swUpdate.isEnabled && !this.initializated) {
      this.initializated = true;
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      ).subscribe(event => {
       console.log(`PwaService:  current = ${event.currentVersion}, available = ${event.latestVersion}`);
       this.swUpdate.activateUpdate().then(() => window.location.reload())
     });
   }
  }
}
