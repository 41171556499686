import { Inject, Injectable } from '@angular/core';
import { ErrorHandler } from "@angular/core";
import { ddMMyyyyHHmmss, LOG_TOKEN, VERSION_APP } from '@config/config';
import { Collections } from '@config/constants';
import { AuthService } from '@services/auth-service/auth-service';
import { ConnectivityService } from '@services/connectivity-service/connectivity-service';
import { FirestoreService } from '@services/firestore-service/firestore-service';
import { StorageService } from '@services/storage-service/storage-service';
import { SystemService } from '@services/system-service/system-service';
import { tzFormat } from '@shared/time';
// import { stacktrace } from '@model/stacktrace';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    @Inject(VERSION_APP) public versionApp,
    @Inject(LOG_TOKEN) private logToken,
    @Inject(ddMMyyyyHHmmss) private ddMMyyyyHHmmss,
    private storageService: StorageService,
    private authService: AuthService,
    private connectivityService: ConnectivityService,
    private systemService: SystemService,
    private fsService: FirestoreService) { }

  handleError(error: any): void {
    this.handleErrorAsync(error);
  }

  private async handleErrorAsync(error: any): Promise<void> {
    /**
     * Intentamos loguear los mensajes
     */
    const message = this.getMessage(error);
    const user = await this.authService.getUserLoggedIn();
    const userId = user?.id ?? null;
    const errorObj = {
      version: this.versionApp,
      message,
      userId,
      localDate: tzFormat(new Date(), this.ddMMyyyyHHmmss),
      url: window.location.pathname,
      class: error?.constructor?.name ?? "N/A"
    }

    try {
      if(this.doSend(errorObj.message)) {
        if (this.connectivityService.isOnline()) {
          await this.fsService.add(Collections.Errors, errorObj, true);
        } else {
          await this.storageService.set(this.logToken, errorObj);
        }
      }
    } catch (err) {
      console.log("################");
      console.log(err);
      console.log("################");
    }

    /**
     * Chequeo si es necesario recargar la página
     */
    const chunkFailedMessage = /Loading chunk [\S]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.systemService.reload();
    }
  }

  /**
   * Filtramos los mensajes de error que contienen OneSignalPageSDKES6 
   * @param message 
   * @return {boolean} 
   */
  private doSend(message: string): boolean {
    return message && 
    message.indexOf("OneSignalPageSDKES6") === -1 &&
    message.indexOf("Failed to start the audio device") === -1 && 
    message.indexOf("IDBFactory.open() called in an invalid security context") === -1;
  }

  // private getMessage(error: any): string {
  //   if(error.stack) {
  //     return error.stack;
  //   }

  //   if (error.message) {
  //     return error.message;
  //   }

  //   const toString = error.toString();
  //   if (toString && toString !== '[object Object]') {
  //     return toString;
  //   }

  //   let message;
  //   try {
  //     message = JSON.stringify(error);
  //   } catch (err) {
  //     message = "No se logró extraer un mensaje";
  //   }

  //   return message;
  // }

  private getMessage(error: any): string {
    const message = [];

    if (error.code) {
      message.push(`error.code = ${error.code}`);
    }

    if (error.message) {
      message.push(`error.message = ${error.message}`);
    }

    if (error.name) {
      message.push(`error.name = ${error.name}`);
    }

    if (error.stack) {
      message.push(`error.stack = ${error.stack}`);
    }

    try {
      if(error.customData) {
        const customData = JSON.stringify(error.customData);
        message.push(`error.customData = ${customData}`);
      }

      if (error.details) {
        const details = JSON.stringify(error.details);
        message.push(`error.details = ${details}`);
      }
    } catch (e) {
      message.push("ERROR EN STRINGIFY " + e.toString());
    }

    if (message.length === 0) {
      message.push("No se logró extraer un mensaje");
    }

    return message.join("|###|");
  }
}

