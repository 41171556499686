import { getTimezone } from "countries-and-timezones";
import { CURRENCY_SYMBOL_MAP, DECIMAL_SEPARATOR_MAP, DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY_SYMBOL, DEFAULT_FORMAT_DATE_OBJECT, DEFAULT_LOCALE, DEFAULT_TZ, FORMAT_DATE_MAP, LOCALE_MAP, THOUSANDS_SEPARATOR_MAP } from "@config/parameters";
import { LocaleInfo } from "@model/types/locale";
import { Country } from "@model/types/country";
import { DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSANDS_SEPARATOR } from "@config/config";
import { Locale } from "date-fns";


export function locale<Localable extends { locale?: LocaleInfo | null}>(localable?: Localable): LocaleInfo {
    return localable?.locale ?? browserLocaleInfo();
}

export function currencySymbol(lc: LocaleInfo): string {
    return CURRENCY_SYMBOL_MAP[lc.country] ?? DEFAULT_CURRENCY_SYMBOL;
}

export function localeObj(lc: LocaleInfo): Locale {
    return LOCALE_MAP[lc.country] ?? DEFAULT_LOCALE;
}

export function toLocalInfo(localable: { locale: LocaleInfo } | LocaleInfo): LocaleInfo {
    return (localable as any).locale ?? localable;
}

export function browserTZ(): string {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return tz ?? DEFAULT_TZ;
}

export function browserCountryCode(): string {
    const timezone = browserTZ();
    const tzObj = getTimezone(timezone);
    return tzObj?.countries[0] ?? DEFAULT_COUNTRY_CODE;
}

export function browserLocaleInfo(): LocaleInfo {
    return { tz: browserTZ(), country: browserCountryCode() };
}

export function formatDateMap() {
    const countryCode = browserCountryCode();
    return FORMAT_DATE_MAP[countryCode] ?? DEFAULT_FORMAT_DATE_OBJECT;
}

export function decimalSeparator(lc: LocaleInfo): string {
    return DECIMAL_SEPARATOR_MAP[lc.country] ?? DEFAULT_DECIMAL_SEPARATOR;
}

export function thousandsSeparator(lc: LocaleInfo): string {
    return THOUSANDS_SEPARATOR_MAP[lc.country] ?? DEFAULT_THOUSANDS_SEPARATOR;
}

export function isCountry(lc: LocaleInfo, country: Country): boolean {
    return lc.country === country;
}

export function isParaguay(lc: LocaleInfo): boolean {
    return isCountry(lc, Country.PARAGUAY);
}

export function isUruguay(lc: LocaleInfo): boolean {
    return isCountry(lc, Country.URUGUAY);
}

export function isEspana(lc: LocaleInfo): boolean {
    return isCountry(lc, Country.ESPANA);
}

export function isGuatemala(lc: LocaleInfo): boolean {
    return isCountry(lc, Country.GUATEMALA);
}