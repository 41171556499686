import { Injectable } from '@angular/core';
import { Collections } from '@config/constants';
import { FirestoreService } from '@services/firestore-service/firestore-service';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private fsService: FirestoreService) {
  }

  public async log(message: string | string []): Promise<void> {
    try {
      message = Array.isArray(message) ? message.join(' # ') : message;
      await this.fsService.add(Collections.Logs, {
        message
      }, true, false);
    } catch(err) {
      console.log("#### LogService ####");
      console.log(err);
      console.log("####################");
    }
  }
}
