import {
  LoadingController,
  AlertController,
  ToastController,
  //Toast,
  ActionSheetController,
  PickerController,
  PickerOptions,
  PickerColumnOption,
  IonicSafeString,
  //ActionSheet
} from '@ionic/angular';

import { Injectable } from '@angular/core';

// utils
import { isNil } from '@shared/lodash';

//model
import { MyError } from '@model/error';

@Injectable({
  providedIn: 'root'
})
export class GUI {

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private pickerController: PickerController,
    private actionSheetCtrl: ActionSheetController) {
  }

  showAlertError(msg: string, callback?: () => void) {
    this.showAlertMessage('⚠️', msg, callback);
  }

  async showAlertSingleMessage(msg: string, callback?: () => void) {
    const alert = await this.alertCtrl.create({
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            if (callback) {
              callback();
            }
          }
        }]
    });

    await alert.present();
  }

  async showAlertMessage(header: string, subHeader: string, callback?: () => void) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            if (callback) {
              callback();
            }
          }
        }]
    });

    await alert.present();
  }

  async showConfirmMessage(
    header: string,
    message: string,
    okCallback?: () => any,
    cancelCallback?: () => any,
  ) {
    const confirm = await this.alertCtrl.create({
      backdropDismiss: false,
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            if (cancelCallback) {
              cancelCallback();
            }
          }
        },
        {
          text: 'OK',
          handler: () => {
            if (okCallback) {
              okCallback();
            }
          }
        }
      ]
    });

    await confirm.present();
  }

  async presentThreeOption(
    header: string,
    message: string,
    positiveText: string,
    negativeText: string,
    positiveCallback?: () => any,
    negativeCallback?: () => any,
    cancelCallback?: () => any,
  ) {
    const confirm = await this.alertCtrl.create({
      backdropDismiss: false,
      header: header,
      message: message,
      buttons: [
        {
          role: 'positive',
          text: positiveText,
          handler: () => {
            if (positiveCallback) {
              positiveCallback();
            }
          }
        },
        {
          role: 'negative',
          text: negativeText,
          handler: () => {
            if (negativeCallback) {
              negativeCallback();
            }
          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            if (cancelCallback) {
              cancelCallback();
            }
          }
        },
      ]
    });

    await confirm.present();
  }

  async presentPrompt(
    header: string,
    message: string = '',
    okCallback?: (data: any) => void,
    cancelCallback?: (data: any) => void) {

    const prompt = await this.alertCtrl.create({
      header: header,
      message: message,
      inputs: [
        {
          name: 'data',
          placeholder: message
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: (result) => {
            if (cancelCallback) {
              cancelCallback(result.data);
            }
          }
        },
        {
          text: 'OK',
          handler: (result) => {
            if (okCallback) {
              okCallback(result.data);
            }
          }
        }
      ]
    });

    await prompt.present();
  }

  async presentLoading(message?: string) {
    const loading = await this.loadingCtrl.create({
      message: !isNil(message) ? message : ''
    });

    await loading.present();
    return loading;
  }

  async presentCircleLoading() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'custom-spinner-circle',
      translucent: true,
      spinner: null,
      message: new IonicSafeString(`
            <div class="cssload-container">
              <div class="cssload-loading">
                <div class="cssload-ani1"><i></i><i></i><i></i></div>
                <div class="cssload-ani2"><i></i><i></i><i></i></div>
                <div class="cssload-ani3"><i></i><i></i><i></i></div>
                <div class="cssload-ani4"><i></i><i></i><i></i></div>
              </div>
            </div>`)
    });

    await loading.present();
    return loading;
  }

  async presentCircleLoadingAndWait<T = any>(func: () => Promise<T>, resolve?: (result: T) => any, reject?: () => any, runCallbackBeforeDismiss: boolean = false) {
    const loading = await this.presentCircleLoading();

    return func().then(result => {
      resolve = resolve ?? ((_) => { });

      if (runCallbackBeforeDismiss) {
        const resolveResult = resolve(result);
        if (resolveResult instanceof Promise) {
          resolveResult.then(() => {
            loading.dismiss();
          });
        } else {
          loading.dismiss();
        }
      } else {
        loading.dismiss().then(() => resolve(result));
      }
    })
      .catch((error: MyError) => {
        loading.dismiss().then(() => {
          this.showAlertError(error.message, reject);
        });
      });
  }

  async presentSpinner(duration: number): Promise<any> {
    const loading = await this.loadingCtrl.create({
      message: '',
      duration: duration
    });

    await loading.present();
    return loading;
  }

  async presentToast(msg: string, duration?: number, ok: boolean = true, position?: string, cssClass?: string) {
    const opt: any = {
      message: msg,
      position: position ? position : 'top',
    };

    if (duration) {
      opt.duration = duration;
    }

    if (cssClass) {
      opt.cssClass = cssClass;
    }

    if (ok) {
      opt.buttons = [{ text: ok ? 'OK' : '' }];
    }

    const toast = await this.toastCtrl.create(opt);

    toast.present();
    return toast;
  }

  async presentActionSheet(buttons: any[], header: string = '') {
    const actionSheet = await this.actionSheetCtrl.create({
      header: header,
      buttons: buttons
    });

    await actionSheet.present();
    return actionSheet;
  }

  async presentSimplePicker<T = any>(options: PickerColumnOption[], onClickOKCallback?: (value: T) => void) {
    const columnName = 'SimplePickerColumnName';
    const selectedIndex = options.findIndex(opt => opt.selected);

    const pickerOptions: PickerOptions = {
      buttons: [
        {
          text: "Cancelar",
          role: 'cancel'
        },
        {
          text: 'OK',
          handler: (column: any) => {
            if (onClickOKCallback) {
              onClickOKCallback(column[columnName].value);
            }
          }
        }
      ],
      columns: [{
        name: columnName,
        options,
        selectedIndex: selectedIndex >= 0 ? selectedIndex : undefined
      }]
    };

    const picker = await this.pickerController.create(pickerOptions);

    await picker.present();
    return picker;
  }

}
