import { Inject, Injectable } from '@angular/core';
import { Functions, getFunctions, HttpsCallable, httpsCallable, httpsCallableFromURL, HttpsCallableResult } from 'firebase/functions';
import { CALLABLE_2ND_GEN_BASE_URL, CALLABLE_GENERATION_VERSION } from '@config/config';

import { MyError } from '@model/error';
import { isNil } from '@shared/lodash';
import { delUndefined } from '@shared/micro-lodash';
import { CallableResult, E_CallableException, OnCallOptions } from './types';

@Injectable({
    providedIn: 'root'
})
export class OnCallService {
    private fns: Functions;

    constructor(
        @Inject(CALLABLE_2ND_GEN_BASE_URL) private callable2ndGenBaseUrl: string,
        @Inject(CALLABLE_GENERATION_VERSION) private callableGenerationVersion: string) {
            this.fns = getFunctions();
        }


    public runCallable<Req = any, Res = any>(callable: string, data: Req, options: Partial<OnCallOptions> = {}): Promise<CallableResult<Res>> {
        const _getVersion = !isNil(options.genVersion) ? options.genVersion : this.callableGenerationVersion;
        
        let callableFn: HttpsCallable<unknown, unknown>;
        if (_getVersion === 1) {
            const _callableName = !isNil(options.callableName) ? options.callableName : 'callables';
            callableFn = httpsCallable(this.fns, _callableName);
        } else {
            callableFn = httpsCallableFromURL(this.fns, this.callable2ndGenBaseUrl);
        }

        const _data = options.delUndefined ? delUndefined(data) : data;
        const _exceptions = !isNil(options.exceptions) ? options.exceptions : E_CallableException.Throw;

        return callableFn({ callable, data: _data })
            .then((result: HttpsCallableResult<CallableResult<Res>>) => result.data)
            .then((result: CallableResult<Res>) => {
                if(!isNil(result) && Array.isArray(result.errors) && result.errors.length > 0) {
                    if(_exceptions === E_CallableException.Throw) {
                        throw new MyError(result.errors[0]);
                    } else {
                        return result;
                    }
                }

                return result;
            });
    }

    public runCallableResult<Req = any, Res = any>(callable: string, data: Req, options: Partial<OnCallOptions> = {}): Promise<Res> {
        return this.runCallable<Req, Res>(callable, data, options).then(callableResult => callableResult.result);
    }
}
