import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Abstract subscriber component to help unsubscribe from hot observables
 */
@Injectable()
export abstract class AbstractSubscriber implements OnDestroy {

  protected ngUnsubscribe = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

export function unsubscribe(subscriptions: any[]) {
    subscriptions.forEach(s => {
        if (typeof s.unsubscribe === 'function') {
            s.unsubscribe();
        }
    });
}