// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .new-container {
  display: flex;
  align-items: center;
}
:host .new-container .icon {
  width: 24px;
  height: 24px;
}
:host .new-container .icon svg {
  fill: blue;
}
:host .new-container .icon text {
  font-weight: 600;
}
:host .new-container .icon .is-light-color {
  fill: white;
}
:host .new-container .count {
  margin-left: 3px;
  font-size: 11px;
  color: blue;
}
:host .new-container .is-light-color {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/my-new/my-new.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;AAEM;EACE,UAAA;AAAR;AAGM;EACE,gBAAA;AADR;AAIM;EACE,WAAA;AAFR;AAMI;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AAJN;AAOI;EACE,YAAA;AALN","sourcesContent":[":host {\n  .new-container {\n    display: flex;\n    align-items: center;\n\n    .icon {\n      width: 24px;\n      height: 24px;\n\n      svg {\n        fill: blue;\n      }\n  \n      text {\n        font-weight: 600;\n      }\n\n      .is-light-color {\n        fill: white;\n      }\n    }\n\n    .count {\n      margin-left: 3px;\n      font-size: 11px;\n      color: blue;\n    }\n\n    .is-light-color {\n      color: white;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
