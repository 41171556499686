import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from '@services/auth-service/auth-service';


export function authorizatedGuard(): CanActivateFn {
  return async () => {
    const navCtrl = inject(NavController);
    const authService = inject(AuthService);
  
    const isLoggedIn = await authService.isLoggedIn();
    if (!isLoggedIn) {
      // logged in so return true
      navCtrl.navigateRoot('login');
    }
  
    return isLoggedIn;
  }
}
