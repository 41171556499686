import { InjectionToken } from '@angular/core';
import { browserLocaleInfo, formatDateMap } from '@shared/locale';
import { isProduction } from './environment';

export const PRINT_CONTAINER_ID_VALUE = 'printContainerId';
export const PRINT_CONTAINER_ID = new InjectionToken<string>('PRINT_CONTAINER_ID');

export const DIHOLA_WEBSITE_VALUE: string = 'https://dihola.uy';
export const DIHOLA_WEBSITE = new InjectionToken<string>('DIHOLA_WEBSITE');

export const BROWSER_LOCALE_INFO_VALUE = browserLocaleInfo();
export const BROWSER_LOCALE_INFO = new InjectionToken<string>('BROWSER_LOCALE_INFO');

export const RESPONSIVE_UMBRAL_VALUE = 750;
export const RESPONSIVE_UMBRAL = new InjectionToken<string>('RESPONSIVE_UMBRAL');

export const GOOGLE_MAP_API_KEY_VALUE = 'AIzaSyDNdxzRrKtQrepUZdNHThwQ3MLYD7sRDDY';
export const GOOGLE_MAP_API_KEY = new InjectionToken<string>('GOOGLE_MAP_API_KEY');

export const SASS_COLORS_VALUE = '#f3696b';
export const SASS_COLORS = new InjectionToken<string>('SASS_COLORS');

export const VERSION_APP_VALUE = '2.6.5';
export const VERSION_APP = new InjectionToken<boolean>('VERSION_APP');

export const DEFAULT_MIN_HOUR_VALUE = "07:00";
export const DEFAULT_MIN_HOUR = new InjectionToken<string>("DEFAULT_MIN_HOUR");

export const DEBUG_VALUE = true;
export const DEBUG = new InjectionToken<string>('DEBUG_MODE');

export const LOG_TOKEN_VALUE = "LOG_TOKEN";
export const LOG_TOKEN = new InjectionToken<string>('LOG_TOKEN');
export const SYSTEM_SERVICE_INTENTS_VALUE = 10;
export const SYSTEM_SERVICE_INTENTS = new InjectionToken<string>('ERROR_HANDLER_INTENTS');
export const SYSTEM_SERVICE_TIMEOUT_VALUE = 3000;
export const SYSTEM_SERVICE_TIMEOUT = new InjectionToken<string>('ERROR_HANDLER_TIMEOUT');

// Separadores por defecto
export const DEFAULT_DECIMAL_SEPARATOR = ".";
export const DEFAULT_THOUSANDS_SEPARATOR = ",";

export const DEFAULT_DELIVERY_TIMES_VALUE = [
    { min: 15, max: 30, desc: '15\' A 30\'', viewInDetailOnly: false },
    { min: 30, max: 45, desc: '30\' A 45\'', viewInDetailOnly: false },
    { min: 45, max: 60, desc: '45\' A 1H', viewInDetailOnly: false },
    { min: 60, max: 90, desc: '1H A 1.5H', viewInDetailOnly: false },
    { min: 90, max: 120, desc: '1.5H A 2H', viewInDetailOnly: true },
    { min: 120, max: 150, desc: '2H A 2.5H', viewInDetailOnly: true },
    { min: 120, max: 180, desc: '2H A 3H', viewInDetailOnly: true }
];
export const DEFAULT_DELIVERY_TIMES = new InjectionToken<string>('DEFAULT_DELIVERY_TIMES');
export const MP3_SOUND_VALUE = 'assets/mp3/austin_powers.mp3';
export const MP3_SOUND = new InjectionToken<string>('MP3_SOUND');

export const MP3_DISCONNECTED_WARN_VALUE = 'assets/mp3/disconnected_warn.mp3';
export const MP3_DISCONNECTED_WARN = new InjectionToken<string>('MP3_DISCONNECTED_WARN');

export const MP3_COMPLAINT_WARN_VALUE = 'assets/mp3/complaint_warn.mp3';
export const MP3_COMPLAINT_WARN = new InjectionToken<string>('MP3_COMPLAINT_WARN');

/**
 * Cada cuanto tiempo vuelve a sonar una order pendiente o una solicitud de información
 */
export const ORDER_SOUND_TIMEOUT_VALUE = 30000;
export const ORDER_SOUND_TIMEOUT = new InjectionToken<string>('ORDER_SOUND_TIMEOUT');

export const COMPLAINT_SOUND_TIMEOUT_VALUE = 45000;
export const COMPLAINT_SOUND_TIMEOUT = new InjectionToken<string>('COMPLAINT_SOUND_TIMEOUT');

export const DEFAULT_MIN_WAITING_TAKEAWAY_TIME_VALUE = 15;
export const DEFAULT_MIN_WAITING_TAKEAWAY_TIME = new InjectionToken<string>('DEFAULT_MIN_WAITING_TAKEAWAY_TIME');

export const FORMAT_DATE_MAP = formatDateMap();
export const Format_ddMMMMddMMMM_Value = FORMAT_DATE_MAP['Format_ddMMMMddMMMM'];
export const Format_ddMMMMddMMMM = new InjectionToken<string>('Format_ddMMMMddMMMM');

export const Format_ddddMMMM_Value = FORMAT_DATE_MAP['Format_ddddMMMM'];
export const Format_ddddMMMM = new InjectionToken<string>('Format_ddddMMMM');

export const Capitalize_ddMMMMddMMMM_Value = FORMAT_DATE_MAP['Capitalize_ddMMMMddMMMM'];
export const Capitalize_ddMMMMddMMMM = new InjectionToken<string>('Capitalize_ddMMMMddMMMM');

export const Capitalize_ddddMMMM_Value = FORMAT_DATE_MAP['Capitalize_ddddMMMM'];
export const Capitalize_ddddMMMM = new InjectionToken<string>('Capitalize_ddddMMMM');

export const Capitalize_EEEEddMMMM_Value = FORMAT_DATE_MAP['Capitalize_EEEEddMMMM'];
export const Capitalize_EEEEddMMMM = new InjectionToken<string>('Capitalize_EEEEddMMMM');

export const Capitalize_ddMMMM_Value = FORMAT_DATE_MAP['Capitalize_ddMMMM'];
export const Capitalize_ddMMMM = new InjectionToken<string>('Capitalize_ddMMMM');

export const ddMMHHmmss_Value = FORMAT_DATE_MAP['ddMMHHmmss'];
export const ddMMHHmmss = new InjectionToken<string>('ddMMHHmmss');

export const ddMMyyyy_Value = FORMAT_DATE_MAP['ddMMyyyy'];
export const ddMMyyyy = new InjectionToken<string>('ddMMyyyy');

export const ddMMyyyyHHmm_Value = FORMAT_DATE_MAP['ddMMyyyyHHmm'];
export const ddMMyyyyHHmm = new InjectionToken<string>('ddMMyyyyHHmm');

export const ddMMyyyyHHmmss_Value = FORMAT_DATE_MAP['ddMMyyyyHHmmss'];
export const ddMMyyyyHHmmss = new InjectionToken<string>('ddMMyyyyHHmmss');

export const MMyyyy_Value = FORMAT_DATE_MAP['MMyyyy'];
export const MMyyyy = new InjectionToken<string>('MMyyyy');

export const ddMM_Value = FORMAT_DATE_MAP['ddMM'];
export const ddMM = new InjectionToken<string>('ddMM');

export const EEEEddMMMM_Value = FORMAT_DATE_MAP['EEEEddMMMM'];
export const EEEEddMMMM = new InjectionToken<string>('EEEEddMMMM');

export const ddMMMM_Value = FORMAT_DATE_MAP['ddMMMM'];
export const ddMMMM = new InjectionToken<string>('ddMMMM');

export const EEEEd_Value = FORMAT_DATE_MAP['EEEEd'];
export const EEEEd = new InjectionToken<string>('EEEEd');

export const RECONNECTION_AUDIO_WARN_SECONDS_VALUE = 5;
export const RECONNECTION_AUDIO_WARN_SECONDS = new InjectionToken<string>('RECONNECTION_AUDIO_WARN_SECONDS');

export const RELOAD_SECONDS_VALUE = 5;
export const RELOAD_SECONDS = new InjectionToken<string>('RELOAD_SECONDS');

export const DEFAULT_HISTORY_DAYS_VALUE = 1;
export const DEFAULT_HISTORY_DAYS = new InjectionToken<string>('DEFAULT_HISTORY_DAYS');

export const DEFAULT_COMPLAINTS_DAYS_VALUE = 7;
export const DEFAULT_COMPLAINTS_DAYS = new InjectionToken<string>('DEFAULT_COMPLAINTS_DAYS');

export const WEB_PUSH_TAG_NAME_VALUE = "backendUserId";
export const WEB_PUSH_TAG_NAME = new InjectionToken<string>('WEB_PUSH_TAG_NAME');

export const DEFAULT_MAX_DATE_VALUE = `${(new Date()).getFullYear() + 1}-12-31T23:59:59.999`;
export const DEFAULT_MAX_DATE = new InjectionToken<string>('DEFAULT_MAX_DATE');

export const MAX_LENGTH_LONG_DETAIL_VALUE = 3000;
export const MAX_LENGTH_LONG_DETAIL = new InjectionToken<string>('MAX_LENGTH_LONG_DETAIL');

export const WAITING_FOR_SYNC_TIMEOUT_VALUE = 10000;
export const WAITING_FOR_SYNC_TIMEOUT = new InjectionToken<string>('WAITING_FOR_SYNC_TIMEOUT');

export const REPORTS_PRODUCTS_COUNT_URL_VALUE = isProduction() ? 
    "https://datastudio.google.com/embed/reporting/cdc8c46b-60bb-4bbf-a2f2-10eb5e40716b/page/IH7xC" :
    "https://datastudio.google.com/embed/reporting/6780c556-f2b4-49c8-828d-bdabba929cac/page/IH7xC";

export const REPORTS_PRODUCTS_COUNT_URL = new InjectionToken<string>('REPORTS_PRODUCTS_COUNT');

export const CALLABLE_2ND_GEN_BASE_URL_VALUE = isProduction() ? 
"https://callablesv2-ko2ie6hrdq-uc.a.run.app" :
"https://callablesv2-lnwjdjesfa-uc.a.run.app";

export const CALLABLE_2ND_GEN_BASE_URL = new InjectionToken<string>("CALLABLE_2ND_GEN_BASE_URL");

// Si se elecuta cloud functions de 1er o 2da generación
export const CALLABLE_GENERATION_VERSION_VALUE = 2;
export const CALLABLE_GENERATION_VERSION = new InjectionToken<string>("CALLABLE_GENERATION_VERSION");

export const POS_DOMAIN_VALUE = ["restopos2.dihola.uy", "restoposdev2.dihola.uy"];
export const POS_DOMAIN = new InjectionToken<string>("POS_DOMAIN");

export const SUNMI_PORT_VALUE = 8089;
export const SUNMI_PORT = new InjectionToken<string>('SUNMI_PORT');

export const SUNMI_HOST_VALUE = "http://localhost";
export const SUNMI_HOST = new InjectionToken<string>('SUNMI_HOST');

export const AUTOMATIC_OPTIN_VOUCHER_VALUE = [10, 15, 20, 25, 30];
export const AUTOMATIC_OPTIN_VOUCHER = new InjectionToken<string>('AUTOMATIC_OPTIN_VOUCHER');

// los valores están en horas, el null significa Permanante
export const AUTO_ENABLED_WHEN_PRODUCTS_VALUE = [24, 12, 6, 1, null];
export const AUTO_ENABLED_WHEN_PRODUCTS = new InjectionToken<string>('AUTO_ENABLED_WHEN_PRODUCTS');

export const AUTO_ENABLED_WHEN_OPEN_CLOSE_VALUE = [30, 60, 360, null];
export const AUTO_ENABLED_WHEN_OPEN_CLOSE = new InjectionToken<string>('AUTO_ENABLED_WHEN_OPEN_CLOSE');
