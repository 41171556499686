import { Inject, Injectable } from '@angular/core';
import { POS_DOMAIN, SYSTEM_SERVICE_INTENTS, SYSTEM_SERVICE_TIMEOUT } from '@config/config';

/**
 * El objetivo de este servicio es brindar información útil del sistema en general
 * Por ejemplo, la estabilidad del sistema.
 * Entendiendo por estabilidad cuando el sistema se quedó sin red ó cuando la red volvió
 * pero aún no se recargó la página
 */
@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private stability: boolean = true;

  constructor(
    @Inject(POS_DOMAIN) private posDomain: string[],
    @Inject(SYSTEM_SERVICE_INTENTS) private systemServiceIntents,
    @Inject(SYSTEM_SERVICE_TIMEOUT) private systemServiceTimeout) {
  }

  setStability(stability: boolean) {
    this.stability = stability;
  }

  public isStable(): boolean {
    return this.stability;
  }

  public reload(intents: number = 0) {
    if (this.isStable()) {
      window.location.reload();
    } else {
      if (intents < this.systemServiceIntents) {
        setTimeout(() => {
          this.reload(intents++);
        }, this.systemServiceTimeout);
      }
    }
  }

  public isPOS(): boolean {
    return this.posDomain.includes(window.location.hostname);
  }
}
