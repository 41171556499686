export interface CallableResult<R> {
    errors: any[];
    result: R | null;
}

export enum E_CallableException {
    Throw = "throw",
    Store = "store"
}

export interface RestOptions {
    delUndefined: boolean;
    exceptions: E_CallableException;
}

export interface OnCallOptions extends RestOptions {
    // por defecto es versión 2
    genVersion?: number;

    callableName: string;
}

export interface CallableOptions extends OnCallOptions {
    // por defecto es "oncall"
    method?: E_CallableOptionsMethod;

}

export enum E_CallableOptionsMethod { OnCall = "oncall", Rest = "rest" }