import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MyError } from '@model/error';
import { isNil } from '@shared/lodash';
import { delUndefined } from '@shared/micro-lodash';
import { CallableResult, E_CallableException, RestOptions } from './types';
import { environment } from '@environments/environment';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@services/auth-service/auth-service';


@Injectable({
    providedIn: 'root'
})
export class RestService {
    constructor(
        private authService: AuthService,
        private http: HttpClient) { }


    public async runCallable<Req = any, Res = any>(callable: string, data: Req, options: Partial<RestOptions> = {}): Promise<CallableResult<Res>> {
        const _data = options.delUndefined ? delUndefined(data) : data;
        const _exceptions = !isNil(options.exceptions) ? options.exceptions : E_CallableException.Throw;

        const url = environment.restEndpoint;
        const body = { callable, data: _data };
        const headers = await this.getHeader();
        const resultObs$ = this.http.post<CallableResult<Res>>(url, body, { headers });

        return firstValueFrom(resultObs$)
            .then((result: CallableResult<Res>) => {
                if (!isNil(result) && Array.isArray(result.errors) && result.errors.length > 0) {
                    if (_exceptions === E_CallableException.Throw) {
                        throw new MyError(result.errors[0]);
                    } else {
                        return result;
                    }
                }

                return result;
            });
    }

    public runCallableResult<Req = any, Res = any>(callable: string, data: Req, options: Partial<RestOptions> = {}): Promise<Res> {
        return this.runCallable<Req, Res>(callable, data, options).then(callableResult => callableResult.result);
    }

    private async getHeader() {
        const token = await this.authService.getIdToken(true) ?? '';
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
}
