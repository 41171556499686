// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  webPushAppId: "5b94707f-c451-4c75-8e4e-bfae0ecd53ae",
  production: false,
  enablePWA: true,
  qrUrlBase: "https://us-central1-dihola-server-dev.cloudfunctions.net/finishedOrder?id=",
  restEndpoint: "https://us-central1-dihola-server-dev.cloudfunctions.net/restEndpoint",
  firebaseConfig:  {
    apiKey: "AIzaSyBv-xq_woI72PU5VH1GyVWzOhOMAZkw07c",
    authDomain: "dihola-server-dev.firebaseapp.com",
    projectId: "dihola-server-dev",
    storageBucket: "dihola-server-dev.appspot.com",
    messagingSenderId: "211013033234",
    appId: "1:211013033234:web:d7a57bfcb712a38874e7cb",
    measurementId: "G-2S5K9CF4KN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
